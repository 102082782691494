<template>
    <section class="blockElement space">
        <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8">
                <div class="modal-content bg-white boxed br-10 ">
                    <Form @submit="submit" ref="resete">
                        <div class="modal-body">
                            <h3 class="mb-0">{{$t('feedback.feedbacktitle')}} </h3>
                            <p>{{$t('feedback.feedbackcontent1')}} </p>
                            <ul class="d-flex align-items-center feedBackicon mt-5 mb-4">
                                <li v-for="react,key in reactionFace" :key="key">
                                    <label class="cutomChechBox radio p-0">
                                    <p class="position-relative mb-0" :class="[{'active':form.reaction==react.value}]">
                                        <img class="vuew sad" :src="`/assets/images/rating/${react.before}`" :alt="$t('feedback_alt.feedalt1')" :title="$t('feedback_alt.feedtitle1')">
                                        <img class="onhover sad" :src="`/assets/images/rating/${react.after}`" :alt="$t('feedback_alt.feedalt1')" :title="$t('feedback_alt.feedtitle1')">
                                        <Field type="radio" name="reaction" :value="react.value" v-model="form.reaction" class="w-100 h-100" :rules="isRequired" />
                                    </p>
                                    </label>
                                </li>
                            </ul>
                            <ErrorMessage class="text-danger" name="reaction" />
                            <div class="form-group">
                                <label>{{$t('feedback.feedbackcontent2')}} </label>
                                <Field type="text" class="form-control" name="name" :placeholder="$t('feedback_alt.feedtitle2')" v-model="form.name"/>
                            </div>
                            <div class="form-group">
                                <label>{{$t('feedback.feedbackcontent3')}} </label>
                                <Field type="email" class="form-control" name="Email Address" :placeholder="$t('feedback_alt.feedtitle3')" v-model="form.email"/>
                            </div>
                            <div class="form-group">
                                <label>{{$t('feedback.feedbackcontent4')}}</label>
                                <Field type="textarea" class="form-control textarea" name="most" :placeholder="$t('feedback_alt.feedtitle4')" v-model="form.most" :rules="isRequired" />
                                <ErrorMessage class="text-danger" name="most" />
                            </div>
                            <div class="form-group">
                                <label>{{$t('feedback.feedbackcontent5')}} </label>
                                <Field type="textarea" class="form-control textarea" name="changed" :placeholder="$t('feedback_alt.feedtitle4')" v-model="form.changed" :rules="isRequired" />
                                <ErrorMessage class="text-danger" name="changed" />
                            </div>
                            <div class="form-group">
                                <label>{{$t('feedback.feedbackcontent6')}} </label>
                                <div class="d-flex align-items-center flex-wrap inlineRadio">
                                    <label class="cutomChechBox radio" v-for="rate,key in recommendZulutrade" :key="key"> 
                                        <Field type="radio" name="recommend" :value="rate" v-model="form.recommend" :rules="isRequired" /><span class="d-block">{{rate}}</span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <ErrorMessage class="text-danger" name="recommend" />
                                <div class="d-flex align-items-center justify-content-between">
                                    <span class="d-flex align-items-center f-12 mb-0"><img width="15" height="15" class="me-2" src="/assets/images/rating/sad.svg" :alt="$t('feedback_alt.feedalt3')">{{$t('feedback.feedbackcontent7')}} </span>
                                    <span class="d-flex align-items-center f-12 mb-0">{{$t('feedback.feedbackcontent8')}} <img width="15" height="15" class="ms-2" src="/assets/images/rating/smile.svg" :alt="$t('feedback_alt.feedalt2')"></span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-end px-3 py-3 border-top">
                            <!-- <button type="button" class="button borderBtn f-14 zulu_btn" @click="resetField()">Cancel</button> -->
                            <button type="submit" class="button fillBtn f-14 mx-2 zulu_btn"  :class="{'disabled' : store.customerDetail?.readOnly}">{{$t('feedback.feedbackcontent9')}}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
import { myStore } from "@/store/pinia-store.ts";
import { Form, Field, ErrorMessage } from "vee-validate";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                form: {
                    most: '',
                    changed: '',
                    reaction: '',
                    recommend: '',
                    name: '',
                    email: ''
                },
                recommendZulutrade: {
                    0: "1",
                    1: "2",
                    2: "3",
                    3: "4",
                    4: "5",
                    5: "6",
                    6: "7",
                    7: "8",
                    8: "9",
                    9: "10",
                },
                reactionFace: {
                    0: {
                        value: "1",
                        before: "sad.svg",
                        after: "sad-fill.svg",
                    },
                    1: {
                        value: "2",
                        before: "not-satisfied.svg",
                        after: "not-satisfied-fill.svg",
                    },
                    2: {
                        value: "3",
                        before: "neutral.svg",
                        after: "neutral-fill.svg",
                    },
                    3: {
                        value: "4",
                        before: "smile.svg",
                        after: "smile-fill.svg",
                    },
                    4: {
                        value: "5",
                        before: "happy.svg",
                        after: "happy-fill.svg",
                    }
                }
            }
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        watch: {
            'store.customerDetail'(){
                if(this.store.customerDetail && Object.keys(this.store.customerDetail).length){
                    this.form.name = this.store.customerDetail.profileName || ''
                    this.form.email = this.store.customerDetail.email || ''
                }
            }
        },
        methods: {
            isRequired(value) {
                if (value && value.trim()) {
                    return true;
                } else {
                    return 'This Field is required';
                }
            },
            submit(){
                let payload = {
                    "replies": [
                        {
                            "questionTag": "1",
                            "reply": this.form.reaction
                        },
                        {
                            "questionTag": "2",
                            "reply": this.form.most
                        },
                        {
                            "questionTag": "3",
                            "reply": this.form.changed
                        },
                        {
                            "questionTag": "4",
                            "reply": this.form.recommend
                        }
                    ]
                }
                this.store.postFeedback(payload,false).then((res)=>{
                    if(res){
                        if(this.$route.query.target && this.$route.query.target == 'mobile'){
                            let details = navigator.userAgent;
                            let regexp = /android|iphone|kindle|ipad/i;
                            if (regexp.test(details)) {
                                window.open("zulutrade://overview");
                            }
                        } else {
                            this.$router.push({name: 'dashboard_portfolio'})
                        // this.resetField()
                        }
                    }
                })
            },
            // resetField(){
            //     this.$refs.resete.resetForm()
            // }
        },
        mounted(){
            if(this.store.customerDetail && Object.keys(this.store.customerDetail).length){
                this.form.name = this.store.customerDetail.profileName
                this.form.email = this.store.customerDetail.email
            }
        }
    }
</script>